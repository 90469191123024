<template>
  <div id="page-container">
    <div class="print-container A4 text-center">
      <div class="row">
        <div class="col-md-6 col-xs-6 col-6">
          <div class="mb-3">
            <label for="sendername" class="form-label"> Name </label>

            <input class="form-control" id="sendername" name="sendername"
              :value="(body.sexType == 'MR' ? 'Herr' : 'Frau') + '/' + body.fname + ' ' + body.lname" disabled />
          </div>
        </div>
        <div class="col-md-6 col-xs-6 col-6">
          <div class="mb-3">
            <label for="phone" class="form-label"> Telefonnummer </label>

            <input class="form-control" id="phone" name="phone" :value="body.usersId.phone ? body.usersId.phone : '--'"
              disabled />
          </div>
        </div>

        <div class="col-md-6 col-xs-6 col-6">
          <div class="mb-3">
            <label for="createdAt" class="form-label"> Datum </label>

            <input class="form-control" id="createdAt" name="createdAt" :value="$durationFormatFullDate(body.createdAt)"
              disabled />
          </div>
        </div>

        <div class="col-md-6 col-xs-6 col-6" v-if="body.packagesPricesId">
          <div class="mb-3">
            <label for="numberPersons" class="form-label">
              Behandlungstyp
            </label>

            <input class="form-control" id="numberPersons" name="numberPersons"
              :value="JSON.parse(body.packagesPricesId.name).de" disabled />
          </div>
        </div>

        <div class="col-md-6 col-xs-6 col-6">
          <div class="mb-3">
            <label for="numberPersons" class="form-label"> Preise </label>

            <input class="form-control" id="numberPersons" name="numberPersons"
              :value="body.packagesPricesId.price + ' EUR'" disabled />
          </div>
        </div>

        <div class="col-md-6 col-xs-6 col-6">
          <div class="mb-3">
            <label for="id" class="form-label"> Nr </label>

            <input class="form-control" id="id" name="id" :value="$codePadding(body.id)" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        name: { en: null, de: null },
        body: { en: null, de: null },
        // email: null,
        inhomepage: 0,
        numberPersons: 0,
        id: 0,
        created_at: "",
      },
      id: null,

      sectionList: [],
      optionsList: [
        { name: "No", value: 0 },
        { name: "Yes", value: 1 },
      ],

      isInvalid: false,
      disabledAdd: false,
      prices: [],
    };
  },
  methods: {
    getPrice(numberPersons) {
      const one = this.prices.find((p) => p.price == numberPersons);
      if (one) {
        return `${one.name} ( ${one.price} )`;
      } else {
        return "--";
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.$http.get(`packages_users/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );

      // this.$http
      //   .post(`appointments/search?limit=100000000`, {
      //     packagesUsersId: this.id,
      //   })
      //   .then(
      //     (res) => {
      //       this.list = res.data.data.items;
      //     },
      //     (err) => {
      //       this.$toast.add({
      //         severity: "error",
      //         summary: "Error",
      //         detail: err.response.data.message,
      //         life: 3000,
      //       });
      //     }
      //   );
    }
  },
};
</script>

<style scoped>
#page-container {
  background-color: #9e9e9e;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=");
  -webkit-transition: left 500ms;
  transition: left 500ms;
}

.print-container {
  background-color: #fff;
  clear: both;
  /*Do something here with A4*/
  padding: 0px 40px 0px 40px;
  z-index: 0;
  /* height: 100vh; */
}

.A4 {
  background: white;
  width: 24cm;
  /* height: 29.7cm; */
  display: block;
  margin: 0 auto;
  padding: 10px 25px;
  /* padding-top: 13cm; */
  padding-bottom: 8cm;
  /* margin-bottom: 0.5cm; */
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  /* overflow-y: scroll; */
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }

  /* size : A4 portrait; */

  body {
    margin: 0;
    padding: 0;
  }

  .A4 {
    box-shadow: none;
    margin: 0;
    width: auto;
    height: auto;
  }
}
</style>
